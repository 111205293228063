<template>
    <div class="search" :key="viewSearchKey">
        <BannerInnerPage />

        <div class="container search__breads">
            <Breads :links="breadLinks" v-if="!getChapterPreloader" />
        </div>

        <Preloader v-if="getChapterPreloader" />

        <div v-else class="search__content">
            <div class="container">
                <Filters
                    :word="searchWord"
                    :chapter-id="chapterId"
                    :filters="filters"
                    :key="filtersKey"
                    @apply="applyByFilters"
                    @clear="clearFilters"
                />
                <br class="search__br">
                <SectionTitle
                    :title="isChapter ? breadLinks[breadLinks.length-1].text : $t('pages.Search.globalTitle')"
                    :hasButton="false"
                    :changeView="true"
                />

                <div class="search__results">
                    <Preloader v-if="resultsPreloader" />
                    <SectionList v-else :is-search-list="true" :view="getResultsView" :items="results" :key="sectionListKey" />
                    <EmptyList v-if="!results.length && !resultsPreloader" :message="$t('components.EmptyList.main')"/>
                </div>

                <Pagination
                    v-if="pagination"
                    :value="pageNumber"
                    :total="pagination.total"
                    :per-page="pagination.per_page"
                    @input="changePage"
                    :key="paginationKey"
                ></Pagination>
            </div>
        </div>

        <Footer />
    </div>
</template>

<script>
import { mapGetters } from "vuex"

import Footer from "@/components/Footer"
import BannerInnerPage from "@/components/BannerInnerPage"
import Filters from "@/components/Filters"
import SectionTitle from "@/components/SectionTitle"
import SectionList from "@/components/SectionList"
import Pagination from "@/components/Pagination"

export default {
    components: {
        Footer,
        BannerInnerPage,
        Filters,
        SectionTitle,
        SectionList,
        Pagination
    },

    data() {
        return {
            viewSearchKey: 0, /* for locale */

            breadLinks: [],
            results: [],
            pagination: null,
            chapterId: null,
            sectionListKey: 0,
            filtersKey: 0,
            paginationKey: 100,
            filters: [],
            getChapterPreloader: true,
            resultsPreloader: true,
            searchWord: '',
            pageNumber: 1
        }
    },

    computed: {
        ...mapGetters(['getResultsView']),
        isChapter() {
            return !!this.$route.params.name
        }
    },

    watch: {
        '$i18n.locale': function() {
            this.getChapter()
        },

        getResultsView() {
            this.sectionListKey += 1
        },

        searchWord(v) {
            if (!this.isChapter) {
                const query = Object.assign({}, this.$route.query);
                v ? query.word = v : delete query.word
                this.$router.replace({ query }).catch(() => {})
            }
        },

        pageNumber(v) {
            const query = Object.assign({}, this.$route.query);
            v != 1 ? query.page = v : delete query.page
            this.$router.replace({ query }).catch(() => {})
        },

        $route(to, from) {
            if (to.name != from.name) {
                this.pageNumber = 1;
                this.getChapter()
            }
            else {
                if (to.params.name != from.params.name) {
                    this.getChapter()
                }
            }
        }
    },

    async mounted() {
        this.getChapter()
    },

    methods: {
        async getChapter() {

            this.breadLinks = [{
                text: '',
                route: this.$route.name
            }]

            this.$route.query?.page ? this.pageNumber = +(this.$route.query.page) : this.pageNumber = 1;
            this.paginationKey += 1;

            try {
                if (this.isChapter) {
                    this.breadLinks.unshift({ text: '', route: '' })
                    this.getChapterPreloader = true
                    await this.getArticles(this.$route.params.name, +this.$route.query.page || 1)
                    await this.getChapterFilters(this.chapterId)
                }
                else {
                    await this.getResultsByWord()
                    this.breadLinks[this.breadLinks.length - 1].text = this.$i18n.t('pages.Search.globalTitle')
                }
                window.scrollTo(0, 0);
            } catch (error) {
                this.getChapterPreloader = false
            }

        },
        getChapterFilters(id) {
            return this.api.filters.index(id).then(r => {
                this.filters = r.map(item => {
                    return {
                        id: item.id,
                        name: item.name,
                        items: item.items.map(e => {
                            return {
                                id: e.id,
                                label: e.name
                            }
                        })
                    }
                })

                this.searchWord = ''
                this.filtersKey += 1
                this.getChapterPreloader = false
            })
        },
        getArticles(chapter, page) {
            return this.api.article.all(chapter, page).then(r => {
                this.breadLinks[this.breadLinks.length - 1].text = r.name
                this.breadLinks[0].text = r.category.name
                this.breadLinks[0].route = `/category/${r.category.uri}`

                this.results = r.posts_pagin.data,
                this.pagination = r.posts_pagin
                this.chapterId = r.id
                this.resultsPreloader = false
            })
        },
        getResultsByWord() {
            this.resultsPreloader = true
            let word = this.$route.query?.word || ''
            return this.api.search.word(word).then(r => {
                this.searchWord = r.query
                this.filters = r.filters.map(item => {
                    if (item.items.length > 0) {
                        return {
                            id: item.id,
                            name: item.name,
                            items: item.items.map(e => {
                                return {
                                    id: e.id,
                                    label: e.name
                                }
                            })
                        }
                    } else{
                        return {}
                    }
                })
                this.results = r.results.items
                this.pagination = r.results

                this.chapterId = null
                this.filtersKey += 1
                this.sectionListKey += 1
                this.resultsPreloader = false
                this.getChapterPreloader = false
            })
        },
        async applyByFilters([filters, a]) {
            this.resultsPreloader = true

            /* applyByFilters запускается при нажатии на кнопку поиска и при изменении query.page */
            let preparePageNum;
            if (this.pageNumber != 1) {
                if (a.applyByButton)  {
                    this.pageNumber = 1;
                    return false
                }
                else {
                    preparePageNum = this.pageNumber || 1
                }
            }
            else {
                a.applyByButton ? preparePageNum = 1 : preparePageNum = this.pageNumber || 1;
            }/* */

            await this.api.search.index(filters, preparePageNum).then(r => {
                this.searchWord = r.query
                this.results = r.results.items
                this.pagination = r.results
                this.paginationKey += 1

                this.resultsPreloader = false
                this.sectionListKey += 1
            }).catch(() => {})

            document.querySelector('.search__br').scrollIntoView({
                behavior: 'smooth',
                block: 'start'
            });
        },
        clearFilters() {
            this.searchWord = '';
        },
        changePage(p) {
            this.pageNumber = p;
        }
    }
}
</script>

<style lang="scss" scoped>
    .search {
        min-height: 100vh;
        display: flex;
        flex-direction: column;

        &__breads {
            padding-top: 30px;
        }

        &__content {
            padding: 50px 0;
        }

        &__results {
            margin-top: 30px;
        }
    }
</style>
