<template>
    <div v-if="total > perPage" class="pagination">
        <Paginate
            v-model="pageNumber"
            :page-count="pages"
            :prev-text="prevHtml"
            :next-text="nextHtml"
            container-class="pagination__content"
            next-class="pagination__page"
            prev-class="pagination__page"
            page-class="pagination__page"
            :hide-prev-next="true"
        />
    </div>
</template>

<script>

export default {
    name: "Pagination",
    props: {
        value: {
            required: true
        },
        total: {
            required: true,
            type: Number
        },
        perPage: {
            required: true,
            type: Number
        }
    },
    data() {
        return {
            pageNumber: this.value || 1,
            nextHtml: '<span aria-hidden="true">&raquo;</span>',
            prevHtml: '<span aria-hidden="true">&laquo;</span>'
        };
    },
    watch: {
        pageNumber() {
            this.$emit("input", this.pageNumber);
        }
    },
    computed: {
        pages() {
            return Math.ceil(this.total / this.perPage);
        }
    },

    methods: {
        setFirstPage() {
            this.pageNumber = 1;
        },
        setLastPage() {
            this.pageNumber = this.pages
        }
    }
};
</script>

<style lang="scss">
.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;

    &__content {
        display: flex;
        align-items: center;
    }

    &__button {
        &--blue {
            box-shadow: 0 4px 10px 0 rgba(53, 0, 212, 0.25);
        }


        &:first-child {
            margin-right: 10px;
        }
        &:last-child {
            margin-left: 10px;
        }
    }

    &__page {
        list-style-type: none;
        margin: 0 3px;
        transition: 0.35s all;
        border-radius: 2px;
        border: solid 1px #cad9f37a;

        a {
            width: 40px;
            height: 40px;
            font-family: $ff-primary;
            display: flex;
            color: $blue;
            align-items: center;
            justify-content: center;
            font-size: 14px;
        }

        &.disabled {
            color: #c2c5cb;
            pointer-events: none;
        }

        &.active,
        &:hover {
            opacity: 1;
            border: solid 1px $blue;
            background: #095581;

            a {
                color: white;
            }
        }
    }
}
</style>
