<template>
    <div class="filters">
        <div class="filters__content" :key="filtersContentKey">

            <div class="filters__item">
                <InputComponent
                    :show-label="true"
                    :placeholder="$t('components.Filters.searchInput')"
                    v-model="search"
                    type="text"
                    name="search"
                />
            </div>

            <div class="filters__item" v-for="f in filters" :key="f.id">
                <SelectComponent
                    v-if="f.name"
                    :show-label="true"
                    :placeholder="f.name"
                    :options="f.items"
                    :name="`filter-${f.id}`"
                    :filter-id="f.id"
                    @input="changeSelect"
                    value="0"
                />
            </div>

        </div>

        <ButtonComponent class="filters__clear" @click="clearFilters" :caption="$t('components.Filters.clear')"/>
        <ButtonComponent class="filters__apply" :theme="'secondary'" @click="applyFilters(true)" :caption="$t('components.Filters.apply')"/>
    </div>
</template>

<script>
import InputComponent from "@/components/form/InputComponent"
import SelectComponent from "@/components/form/SelectComponent"

export default {
    components: {
        InputComponent,
        SelectComponent
    },

    props: {
        filters: {
            required: true,
            type: Array
        },

        word: {
            required: false,
            type: String,
            default: ''
        },

        chapterId: {
            required: true,
            default: [Object, Number],
        }
    },

    data() {
        return {
            search: this.word,
            appliedFilters: [],
            filtersContentKey: 0
        }
    },

    watch: {
        '$route.query.page'() {
            this.applyFilters()
        }
    },

    mounted() {
        if (this.$route.query?.page) {
            this.applyFilters()
        }
    },

    methods: {
        changeSelect(val, selectId) {
            this.appliedFilters.push({
                dictionary_id: selectId,
                dictionary_page_id: val
            })

            this.appliedFilters = this.appliedFilters.reverse()
                .filter((v, i, arr) => arr.findIndex(t => (t.dictionary_id === v.dictionary_id)) === i)
        },

        applyFilters(byButton = null) {
            this.$emit('apply', [{
                query: this.search,
                filters: this.appliedFilters,
                pageId: this.chapterId,
            }, { applyByButton : byButton }])
        },

        clearFilters() {
            this.search = '';
            this.appliedFilters = [];
            this.filtersContentKey += 1;
            this.$emit('clear')
        },
    }

}
</script>

<style lang="scss" scoped>
    .filters {
        display: flex;
        align-items: flex-end;

        @media screen and (max-width: $sm) {
            flex-wrap: wrap;
        }

        &__content {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
        }

        &__item {
            position: relative;
            margin-right: 10px;
            margin-bottom: 15px;

            @media screen and (max-width: $lg) {
                margin-right: 0;
            }

            @media screen and (max-width: $sm) {
                width: 100%;
            }
        }

        &__apply, &__clear {
            margin-bottom: 15px;
        }

        &__apply {
            margin-left: 5px;
        }

    }
</style>
